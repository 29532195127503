import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CONFIG_TOKEN, NotificationModuleOptions } from './notification.types';
import { NotificationDataService } from './services/notification.data.service';
import { NotificationService } from './services/notification.service';
import { SignalRService } from './services/signalr.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [],
  exports: [NotificationsComponent],
  imports: [CommonModule, UIModule, NotificationsComponent],
  providers: [
    NotificationDataService,
    NotificationService,
    SignalRService,
    UserService
  ]
})
export class NotificationModule {
  public static forRoot(
    configuration: NotificationModuleOptions
  ): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: CONFIG_TOKEN,
          useValue: configuration
        }
      ]
    };
  }
}
