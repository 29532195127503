import {
  GetNumberOfUnreadNotificationsResponse,
  GetRecentNotificationsResponse,
  NotificationType,
  SenderType,
  TopicType
} from '../domain';

// TODO: Remove this when we have some data on BE

export const unreadNotificationsMock: GetNumberOfUnreadNotificationsResponse = {
  count: 10
};
export const recentNotificationsMock: GetRecentNotificationsResponse = {
  paginationMetadata: {
    currentPage: 1,
    totalItems: 5,
    totalPages: 1,
    itemsPerPage: 10
  },
  items: [
    {
      id: '1',
      type: NotificationType.Success,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'A success title',
        message: 'Lorem ipmsum',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
      },
      sender: {
        type: SenderType.System,
        name: 'CIRS'
      },
      recipientId: ''
    },
    {
      id: '2',
      type: NotificationType.Error,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'An error title',
        message: 'Lorem ipmsum again',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
      },
      sender: {
        type: SenderType.System,
        name: 'VRS'
      },
      recipientId: ''
    },
    {
      id: '3',
      type: NotificationType.Information,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'An info title',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        message: 'Lorem ipmsum ... yeah, again'
      },
      sender: {
        type: SenderType.System,
        name: 'VRS'
      },
      recipientId: ''
    },
    {
      id: '4',
      type: NotificationType.Information,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'An info title',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        message: 'Lorem ipmsum ... yeah, again'
      },
      sender: {
        type: SenderType.System,
        name: 'VRS'
      },
      recipientId: ''
    },
    {
      id: '5',
      type: NotificationType.Information,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'An info title',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        message: 'Lorem ipmsum ... yeah, again'
      },
      sender: {
        type: SenderType.System,
        name: 'VRS'
      },
      recipientId: ''
    },
    {
      id: '6',
      type: NotificationType.Information,
      created: '',
      modified: '',
      topic: TopicType.ImageExport,
      read: false,
      hidden: false,
      content: {
        title: 'An info title',
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        message: 'Lorem ipmsum ... yeah, again'
      },
      sender: {
        type: SenderType.System,
        name: 'VRS'
      },
      recipientId: ''
    }
  ]
};
