/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ContentDto {
  title: string;
  message: string;
  /** @format uri */
  link?: string;
  metadata?: Record<string, string>;
}

export interface GetNumberOfUnreadNotificationsResponse {
  /** @format int64 */
  count: number;
}

export interface GetRecentNotificationsResponse {
  items: NotificationDto[];
  paginationMetadata: PaginationMetadata;
}

export interface NotificationDto {
  id: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  modified?: string;
  recipientId: string;
  sender: SenderDto;
  topic: TopicType;
  type: NotificationType;
  content: ContentDto;
  read: boolean;
  hidden: boolean;
}

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Information = 'information'
}

export interface PaginationMetadata {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface SenderDto {
  id?: string;
  type: SenderType;
  name?: string;
}

export enum SenderType {
  System = 'system',
  User = 'user'
}

export enum TopicType {
  ImageExport = 'imageExport',
  VideoExport = 'videoExport'
}

export interface NotificationsRecentListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * @format int32
   * @default 10
   */
  itemsPerPage?: number;
  recipientId?: string;
}

export interface NotificationsUnreadCountListParams {
  recipientId?: string;
}

export interface NotificationsMarkAsReadPartialUpdateParams {
  recipientId?: string;
  notificationId: string;
}
