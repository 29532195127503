import { Component, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationService } from '../../../../services/notification.service';

// TODO: Check with UX if we want to keep this or not
@Component({
  standalone: true,
  imports: [UIModule],
  selector: 'ns-popover-footer',
  templateUrl: './popover-footer.component.html',
  styleUrl: './popover-footer.component.scss'
})
export class PopoverFooterComponent {
  private notificationService = inject(NotificationService);

  loading = this.notificationService.loading;
  canLoadMore = this.notificationService.canLoadMore;

  loadMore(): void {
    this.notificationService.loadMoreNotifications();
  }
}
