import { Component, computed, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  standalone: true,
  imports: [UIModule],
  selector: 'ns-popover-header',
  templateUrl: './popover-header.component.html',
  styleUrl: './popover-header.component.scss'
})
export class PopoverHeaderComponent {
  private notificationService = inject(NotificationService);

  isShowingAll = computed(() => this.computeIsShowingAll());

  markAllAsRead(): void {
    this.notificationService.markAllAsRead();
  }

  showUnreadClicked(): void {
    this.notificationService.setFilter('showUnread');
  }

  showAllClicked(): void {
    this.notificationService.setFilter('showAll');
  }

  private computeIsShowingAll(): boolean {
    const currentFilter = this.notificationService.currentFilter();
    return currentFilter === 'showAll';
  }
}
