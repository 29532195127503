@let _notifications = notifications();

<div
  class="wrapper"
  [class.empty]="!_notifications.length">
  @if (!_notifications.length) {
    <span>Nothing to show 😭</span>
  } @else {
    @for (notification of _notifications; track notification.id) {
      <ns-notification-item [notification]="notification" />
    }
  }
</div>
